<template>
    <div>
    </div>
</template>
<script>
export default {
    mounted() {
        this.$router.push({name: 'portal'})
    }
}
</script>
